<template>
  <div class="p-inputgroup">
    <Button
      icon="pi pi-trash"
      severity="danger"
      outlined
      aria-label="Remove"
      @click="removeItem"
      tabindex="-1"
    />
    <InputText
      ref="focusMe"
      :model-value="label"
      @blur="updateLabel($event)"
      @submit="updateLabel($event)"
    ></InputText>
    <InputNumber
      v-if="!Fairmode"
      :modelValue="weight"
      @update:modelValue="updateWeight($event)"
      @change="updateWeight($event)"
      showButtons
      :step="1"
      decrementButtonClass="p-button-secondary"
      incrementButtonClass="p-button-secondary"
      mode="decimal"
      :style="{
        maxWidth: 'fit-content'
      }"
      :pt="{
        input: {
          style: { minWidth: '4em', maxWidth: '4em', borderRadius: '0', textAlign: 'center' }
        }
      }"
      :min="1"
    >
      <template #incrementbuttonicon><i class="pi pi-plus"></i></template>
      <template #decrementbuttonicon><i class="pi pi-minus"></i></template>
    </InputNumber>
  </div>
</template>

<script setup lang="ts">
import { inject, ref, onMounted } from 'vue';
import { ItemService } from '@/services/ItemService';
import { Fairmode } from '@/services/SettingService';
import io from "socket.io-client";

const props = defineProps(['modelValue']);

const itemService = inject<ItemService>('ItemService');

const label = ref(props.modelValue.label);
const weight = ref(props.modelValue.weight);
const focusMe = ref();

function updateLabel(value: Event) {
  const input = value.target as HTMLInputElement;
  const item = props.modelValue;
  console.log(item)
  if (input.value === item.label) return;

  label.value = input.value;
  item.label = input.value;
  console.log('Item Id:' + item._id);
  itemService?.updateItem(item);
}

function updateWeight(value: Number) {
  const item = props.modelValue;
  console.log(item);
  if (value === item.weight) return;

  weight.value = value;
  item.weight = value;
  itemService?.updateItem(item);
}

// function updateWeightStream(value: Number) {
//   const item = props.modelValue;
//   if (value === item.weight) return;

//   weight.value = value;
//   item.weight = value;
//   itemService?.updateItemStream(item);
// }

function removeItem() {
  itemService?.removeItem(props.modelValue);
}

onMounted(() => {
  focusMe.value.$el.focus();
});

// const socketToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbiI6IjlBNTQ5MTZCQURCOENCRjRFQzczIiwicmVhZF9vbmx5Ijp0cnVlLCJwcmV2ZW50X21hc3RlciI6dHJ1ZSwieW91dHViZV9pZCI6IlVDSU56OUZKY3BjZWRsR3NZS1hRa29wQSJ9.0uM1sOHIjebnxazQyZLmHtnOZ5GepAfZ53mZpWcjWqw'; //Socket token from /socket/token end point

// //Connect to socket
// const streamlabs = io(`https://sockets.streamlabs.com?token=${socketToken}`, { transports: ['websocket'] });

// streamlabs.on ('event', async (eventData) => {
//   if (eventData.type === 'donation' || eventData.type === "superchat") {
//     console.table(eventData.message[0]);
//     // itemService?.addItemStream(eventData.);
//     if(eventData.message[0].amount>=50)
//     {
//       let calWeight: number = Math.round(eventData.message[0].amount/50)
//       const item = props.modelValue;
      
//       item.weight = calWeight;
//       // weight.value = await itemService?.updateItemStream(item);
//       await itemService?.updateItemStream(item);
//     }
    
//   }
// });



</script>

<style scoped></style>
